import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    margin-bottom: 0;
  }
`

export default function storyblokTileProductTitle({ data }) {
  return (
    <Container>
      <div className="roofline">{data.roofline}</div>
      <h1>{data.title}</h1>
      <div className="subline">{data.subline}</div>
    </Container>
  )
}
